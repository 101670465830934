.widgets__top-leagues {

  #top-leagues a {
    line-height: 30px;
    vertical-align: middle;
  }

  #top-leagues span {
    margin-left: 5px;
    line-height: 30px;
    vertical-align: middle;
  }

  #top-leagues .list-stage-flag {
    line-height: 30px;
    vertical-align: middle;
  }

  #top-leagues ul {
    margin: 0px;
  }

  #top-leagues ul li {
    line-height: 30px;
  }

}
